@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$player-button-color: #b3b3b3;
$player-button-hover-color: $white;
$player-button-disabled-color: #535353;

.react-slider {
  width: 100%;
  height: 0.2rem;

  .thumb {
    height: 0.35rem;
    width: 0.2rem;
    background: $gray-400;
    border-radius: 2px;
    outline: none;
  }

  .track {
    height: 0.35rem;
    background: $gray-800;
    border-radius: 2px;
  }

  .track-0 {
    background: $gray-600;
  }

  &:active:not(.disabled),
  &:hover:not(.disabled) {
    .thumb {
      top: -0.2rem;
      height: 0.8rem;
      width: 0.8rem;
      background: $white;
      border-radius: 50%;
    }

    .track-0 {
      background: $success;
    }
  }
}
