@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.headerNavbar {
  display: flex;
  flex-direction: row;

  a {
    color: $dark;
    fill: $dark;
    text-decoration: none;
    outline: none;

    &:link,
    &:visited {
      color: $dark;
      fill: $dark;
    }

    &:active,
    &:focus,
    &:hover {
      color: tint-color($dark, 40%);
      fill: tint-color($dark, 40%);
    }
  }

  .logo {
    color: $blue;

    &:link,
    &:visited {
      color: $blue;
      fill: $blue;
    }
  }
}

.searchBar {
  display: flex;
  max-width: 25rem;
}

.hoverGrow {
  transition: none 33ms cubic-bezier(0.3, 0, 0.7, 1);
  transition-property: all;

  @media (min-device-pixel-ratio: 2) {
    transition-duration: 50ms;
  }

  &:focus,
  &:hover {
    transition: none 33ms cubic-bezier(0.3, 0, 0, 1);
    transform: scale(1.06);

    @media (min-device-pixel-ratio: 2) {
      transition-duration: 66ms;
    }
  }

  &:active {
    transition: none;
    transform: scale(0.99);
  }
}

.donateButton {
  color: $pink !important;
  fill: $pink !important;

  &:link,
  &:visited {
    color: $dark;
    fill: $dark;
  }

  &:focus,
  &:hover {
    color: tint-color($pink, 40%) !important;
    fill: tint-color($pink, 40%) !important;
  }

  &:active {
    border-color: $pink !important;
  }
}

.userImage {
  height: 2rem;
}

.randomConcert {
  color: $green !important;
  fill: $green !important;
  outline: 0;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

//.navScroller {
//  position: relative;
//  z-index: 2;
//  height: 2.75rem;
//  overflow-y: hidden;
//
//  .nav {
//    display: flex;
//    flex-wrap: nowrap;
//    padding-bottom: 1rem;
//    margin-top: -1px;
//    overflow-x: auto;
//    text-align: center;
//    white-space: nowrap;
//    -webkit-overflow-scrolling: touch;
//  }
//}
