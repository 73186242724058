@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.playerVisible {
  padding-bottom: 90px;
}

.grid {
  height: 100vh;
  width: 100vw;
  display: grid;
  /* Only one column for base grid */
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}

.gridBody {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    grid-template-columns: auto;
  }
}

.mainBody {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chatColumn {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

@include media-breakpoint-down(lg) {
  .chatColumn {
    display: none;
  }
}

.footerLogo {
  height: 2rem;
}
