@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.messageContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.systemMessage {
  background-color: #fef8c0;
  border-radius: 8px;
}

.mentionMessage {
  background-color: #eff5ff;
  border-radius: 8px;
}

.messageText {
  padding: 0.4rem;
  white-space: pre-line;
}

.messageDetails {
  color: $secondary;

  padding-left: 0.4rem;
  display: flex;
  flex-direction: row;
}

.messageDate {
  margin-left: 1rem;
}

.chatInputArea {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
}

.disabledChatInputArea {
  background: rgba(0, 0, 0, 0.03);
}

.chatTextArea {
  width: 100%;
  min-height: 44px;
  margin: 0;
  padding: 0;
  background: $white;
  border: 0;
  outline: none;
  color: $black;
  resize: none;

  &:disabled {
    background: transparent;
    color: $gray-600;
  }
}
