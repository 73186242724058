@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.searchContainer {
  margin-bottom: auto;
}

.searchItemList {
  &:empty {
    display: none;
  }
}

.searchItem {
  cursor: pointer;
}

.poweredByLink {
  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: inherit !important;
  }
}

.algoliaLogo {
  height: 1.3rem;
}
