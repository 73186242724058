@import '~bootstrap/scss/bootstrap';
@import './type';
@import './react-slider';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .sticky#{$infix}-bottom {
      position: sticky;
      bottom: 0;
      z-index: $zindex-sticky;
    }
  }
}

html,
body {
  height: 100% !important;
  overflow: hidden;
}

#__next {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bg-dark {
  .nav-link {
    svg {
      fill: $navbar-dark-color;
    }

    &:hover {
      svg {
        fill: $navbar-dark-hover-color;
      }
    }

    &:active {
      svg {
        fill: $navbar-dark-active-color;
      }
    }
  }
}

.white-space-pre-line {
  white-space: pre-line;
}
